import React from "react"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

/*Local files */

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    backgroundColor: "#F0F0F0",
    width: "100%",
    height: "auto",
    background: "linear-gradient(to bottom, #FFF, #D5E0FF)",
  },
  Texto: {
    color: "#385099",
    fontSize: "30px",
    font: "400 17px/30px Montserrat",
    "@media (max-width: 720px)": {
      fontSize: "20px",
      color: "#313131",
    },
  },
  titulo: {
    color: "#385099",
    fontSize: "42px",
    marginBottom: "1rem",
    marginTop: "5rem",
    font: "800 34px/42px Montserrat",
    "@media (max-width: 720px)": {
      marginTop: "2rem",
      fontSize: "30px",
      color: "#4267CB",
    },
  },
}))

const SectionOne = () => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.ContenedorMain}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        xs={11}
        md={10}
      >
        <Typography className={classes.titulo}>Aviso de Privacidad</Typography>
      </Grid>
      <Grid xs={11} md={10}>
        <p className={classes.Texto}>
          {`
TÉRMINOS Y CONDICIONES

Los presentes Términos y Condiciones de uso, ya que el acceso y uso de ésta o cualquiera de nuestras Plataformas Web implica la aceptación de este documento y comprende un acuerdo de voluntades entre usted como Usuario de la plataforma y nosotros, SPRINGLABS (Corporativo Axxum S.A. de C.V.), como prestador de servicios de software.

ÍNDICE:
I.- DEFINICIONES
II.- CONDICIONES GENERALES
III.- DE LOS SERVICIOS
IV.- RESTRICCIONES Y RESPONSABILIDAD
V.-- PROPIEDAD INTELECTUAL
VI.-- PRIVACIDAD Y CONFIDENCIALIDAD
VII.-- COMUNICACIONES
VIII.- LEY APLICABLE Y JURISDICCIÓN

I.- DEFINICIONES
Para efectos de los presentes Términos y Condiciones se entenderá por:
1- Términos: Los presentes Términos y Condiciones en su integridad, incluyendo aquellos términos y condiciones por los servicios individuales o específicos ofertados por en nuestro Plataforma Web.
2- SPRINGLABS: La persona moral: Corporativo Axxum S.A. de C.V. y sus sociedades filiales, subsidiarias, unidades de negocio, empresas matrices, empleados, directivos y consejeros, así como, sus subcontratistas, distribuidores, proveedores de contenidos, oficiales, funcionarios de sus empresas y en general cualquier persona vinculada directa o indirectamente con la misma.
3- Usuario: En general toda persona que utiliza las distintas Plataformas Web y Servicios, que SPRINGLABS pone a su disposición, como persona física o persona moral o jurídica, bajo responsabilidad de quién legalmente puede contratar en su nombre y representación.
4- Las Partes: Se les denominará en forma conjunta como las “Partes” cuando se hable del Usuario y SPRINGLABS.
5- Plataforma Web/Plataformas Web: Indistintamente todos los portales web ya sea: páginas o plataformas web, extensiones, plug-ins, aplicaciones móviles para Android y/o IOS, desarrollados y puestos a disposición por SPRINGLABS.
6- Servicios: En general, todas las herramientas de software, aplicaciones y funciones digitales o en línea que SPRINGLABS pone a disposición de cualquier persona a través de sus Plataformas Web, en términos de la definición anterior.
7- Software: Las Plataformas Web o softwares ofertadas por SPRINGLABS y a las que accede el Usuario, de manera conjunta o independiente, según sea el caso, en adelante serán denominados como el “Software”.
8- Gestión Multiplataforma: Software cuyas funcionalidades se relacionan con la administración y gestión de interacciones por canales de comunicación de Internet como correo electrónico, formularios web, Twitter, Facebook, WhatsApp, Instagram, chats insertables en sitios web y aplicaciones móviles, entre otros canales de comunicación y redes sociales. A través del cual, el Usuario puede gestionar sus actividades de contacto de clientes, venta y postventa.
9- Chatbot Multiplataforma: Software cuyas funcionalidades se relacionan con la construcción, implementación y mantenimiento de uno o más chatbots, automatizados con inteligencia artificial, para la atención automática de clientes a través de internet en medios como correo electrónico, formularios web, Twitter, Facebook, WhatsApp, Instagram, chats insertables en sitios web y aplicaciones móviles, entre otros canales de comunicación y redes sociales.
10- Contenidos: En general todo el material publicado en los Servicios y/o las Plataformas Web refiriéndonos de manera enunciativa mas no limitativa a todos los elementos de texto y gráficos, todos los mensajes de datos o informaciones, los títulos, información de Usuarios, datos personales de los Usuarios así como de terceros que estos recaben y traten de acuerdo a las disposiciones legales aplicables, interfaces, UX y UI, vídeos, efectos audiovisuales, y cualquier otro elemento que aparezca en uno o varios de los Servicios, en conjunto con los correspondientes derechos de propiedad intelectual e industrial, marcas comerciales, patentes, modelos de utilidad, derechos de diseño registrado y no registrado, nombres de dominio, acrónimos, logotipos, dibujos, programas de ordenador y bases de datos, así como cualquiera de sus componentes – incluyendo sin limitar código fuente, código objeto, iconos, diseño de interfaz y datos – que les sean inherente y cualquier información o datos de terceros que se encuentren en la Plataforma Web y en los Servicio.
11- Condiciones Especiales: Todas aquellas reglas que rijan el uso de determinado Servicio, Contenido y/o Plataforma Web que debido a las circunstancias especiales de dichos Servicios se deban regir de forma especial y que en su momento serán notificadas al Usuario.
12- Contratación: La suscripción en línea del o de los Servicios ofertados por SPRINGLABS.

II.- CONDICIONES GENERALES
Los presentes términos rigen la relación entre SPRINGLABS y usted (en lo sucesivo, el “Usuario”, “usted” o “su” de manera indistinta) que utiliza la Plataforma Web o cualquier de los distintos Servicios que SPRINGLABS pone a su disposición.

Aceptación de los Términos
Aceptar los presentes Términos como Usuario implica, de manera automática, la libre, informada y plena voluntad y aceptación, de estos Términos y de las Condiciones Especiales que se pudiesen incluir dentro cada Servicio o dentro de la Plataforma Web.
Si el Usuario no está de acuerdo con estos Términos y Condiciones, no debe aceptarlos, pero lamentablemente no podrá disfrutar de esta Plataforma Web ni acceder a los Servicios que SPRINGLABS ofrece. En caso de aceptar los Términos, el Usuario se adhiere a los mismos.
El Usuario puede acceder y utilizar los Servicios para su uso personal y comercial o para fines comerciales internos de la organización que representa. Puede acceder a los Servicios a través de cualquier navegador de internet compatible con estos. Usted es responsable de obtener el acceso a Internet y el equipo necesario para utilizar los Servicios. La aplicación de estos Términos se extiende a todas las normas aplicables a nivel local, estatal, nacional e internacional que sean requeridas para su cumplimiento.

Modificación de los Términos
SPRINGLABS se reserva el derecho de cambiar, modificar, añadir o suprimir condiciones o cláusulas en los presentes Términos. En tal caso daremos previo aviso, a través de un anuncio en la Plataforma Web y/o enviándole un correo electrónico a su dirección de correo electrónico principal.
En caso cambios importantes a los Términos, mismo que pudieran afectar sus derechos como Usuario, se enviará un aviso de los cambios por medio de la Plataforma Web y/o a su dirección de correo electrónico principal.
En tal circunstancia, el Usuario puede cancelar el uso de los Servicios enviando a SPRINGLABS un aviso por correo electrónico dentro de un plazo de 30 días naturales desde la recepción de la notificación de la disponibilidad de los Términos modificados, si los Términos se modifican de una manera que afecta considerablemente sus derechos en relación con el uso de los Servicios. En tal caso, tendrá derecho a un reembolso prorrateado de la parte no utilizada de cualquier cargo pagado previamente. Su uso continuado del servicio después de la fecha de entrada en vigencia de cualquier cambio en los términos se considerará como su aceptación a los Términos modificados.

III.- DE LOS SERVICIOS
Por medio de los presentes Términos, SPRINGLABS se compromete a prestar y facilitar los Servicios de Software Gestión Multiplataforma, Chatbot Multiplataforma y cualquier otro servicio, durante el período y de acuerdo a los Términos o Condiciones Especiales que se deriven de la Contratación de los Servicios. Asimismo, se podrán incorporar nuevos Servicios o modificar los existentes.
Los Servicios de SPRINGLABS se otorgan mediante la Contratación de diversas modalidades, como pudieren ser los servicios gratuitos, los contratación mensual o anual de licencias de uso de Software, de manera temporal y condicionada, bajo el tiempo y condiciones que se pacten en la Contratación de los Servicios en la Plataforma Web, entre otros.
Es responsabilidad única de SPRINGLABS contar con los derechos sobre Software objeto de licencias de los Servicios. En caso de la utilización de un software, plataforma u obra de un tercero, SPRINGLABS no será responsable y es el Usuario quien deberá contar con las autorizaciones o licencias necesarias para utilizar dichos software u obras. En virtud de lo anterior, se obliga a hacerse cargo exclusivamente de todas las responsabilidades de cualquier naturaleza que le correspondan.

Todo Usuario por el simple hecho de acceder al Servicio se obliga a: a) Hacer uso del Servicio únicamente para fines lícitos y/o en forma diligente y correcta. b) Cumplir con todas y cada una de las demás obligaciones establecidas en las Condiciones, así como las demás que puedan inferirse de un uso correcto, de buena fe y/o racional del Servicio. c) No vulnerar derechos de terceros. El incumplimiento de cualquiera de estas obligaciones y/o de otras contempladas en el presente documento, dará lugar a las responsabilidades administrativas, civiles y/o penales correspondientes. SPRINGLABS, sus funcionarios, directores o empleados, no serán responsables en forma alguna de las consecuencias que, por el incumplimiento de cualquiera de las obligaciones o prohibiciones mencionadas en el presente numeral o de las presentes Condiciones, se puedan generar.

Registro de Usuarios
El Usuario debe registrarse con una cuenta de usuario proporcionando toda la información necesaria para acceder a la Plataforma Web y/o utilizar los Servicios. Si representa a una empresa u organización y desea utilizar los Servicios para la misma, recomendamos que todos los Usuarios de dicha organización, inicien sesión en las cuentas de usuario proporcionando su información de contacto empresarial. Particularmente, recomendamos que utilice su dirección de correo electrónico corporativo.
El Usuario se compromete a: 1) proporcionar información verdadera, exacta, actual y completa según lo solicite el proceso de registro de la Plataforma Web; y 2) mantener y actualizar oportunamente la información proporcionada durante el registro para que sea verdadera, exacta, actual y completa. Si proporciona información falsa, inexacta, desactualizada o incompleta, o si SPRINGLABS tiene motivos razonables para sospechar que dicha información es falsa, inexacta, desactualizada o incompleta, podemos cancelar su cuenta de usuario y rechazar el uso actual o futuro de uno o de todos los Servicios.

Cuentas y Administración
Cuando el Usuario se registre para obtener una cuenta para su organización, podrá designar a uno o más administradores. Los administradores tendrán el derecho de configurar los Servicios en función de sus necesidades y gestionar los usuarios finales en la cuenta de su organización. Si un tercero creó y configuró la cuenta de su organización en su nombre, es probable que dicho tercero haya asumido el rol de administrador de su organización. Asegúrese de celebrar un acuerdo adecuado con dichos terceros, especificando las funciones de la parte y las restricciones como administrador de la cuenta de su organización.
Usted es responsable de i) garantizar la confidencialidad de la contraseña de la cuenta de su organización, ii) designar personas competentes como administradores para que administren la cuenta de su organización, y iii) asegurarse de que todas las actividades que ocurran en conexión con la cuenta de su organización cumplan con este acuerdo. Usted entiende que SPRINGLABS no es responsable de la administración de la cuenta y la gestión interna de los servicios que se le brindan.
Usted es responsable de tomar las medidas necesarias para asegurarse de que su organización no pierda el control de las cuentas de administrador. Puede especificar un proceso de recuperación que debe seguirse en caso de que se pierda el control de las cuentas de administrador enviando un correo electrónico a los medios de contacto que aparecen en la página web o Plataforma Web de SPRINGLABS, siempre que el proceso sea aceptable para SPRINGLABS. En la ausencia de cualquier proceso de recuperación de la cuenta de administrador especificada, es posible que SPRINGLABS proporcione el control de una cuenta de administrador a una persona que proporcione una prueba satisfactoria para SPRINGLABS que demuestre autorización para actuar en nombre de la organización. Usted se compromete a no declarar a SPRINGLABS responsable por las consecuencias de cualquier acción que SPRINGLABS considere de buena fe en este sentido.

Cuentas de Usuario Inactivas, Suspendidas o Canceladas
Nos reservamos el derecho a cancelar las licencias o toda la cuenta de Usuario, incluyendo la cuenta de toda una organización, que no estén pagadas e inactivas durante un período continuo de 120 días naturales. En el caso de dicha cancelación, se eliminarán todos los datos asociados con dicha cuenta de Usuario.
Le daremos un aviso previo de dicha cancelación y la opción de realizar una copia de seguridad de sus datos. Es posible que la postura de eliminación de datos se aplique con respecto a algunos o todos los servicios. En el caso de cuentas con más de un Usuario, si al menos uno de los Usuarios está activo, la cuenta no se considerará inactiva.
Es posible que suspendamos su cuenta de Usuario o deshabilitemos temporalmente el acceso a la totalidad o parte de cualquier Servicio en caso de sospecha de actividad ilegal, períodos prolongados de inactividad, como se menciona, o solicitudes de las autoridades u otras agencias gubernamentales. Las objeciones a la suspensión o inhabilitación de cuentas de usuario deben hacerse a soporte.mbn035@springlabs.net dentro del plazo de treinta días de haber sido notificado sobre la suspensión. Es posible que cancelemos una cuenta de Usuario que se encuentre suspendida o desactivada después de treinta días o de ciento veinte días, según el caso. También cancelaremos su cuenta de Usuario según lo solicite.
Además, nos reservamos el derecho de cancelar su cuenta de Usuario y denegar los Servicios si creemos que infringió los Términos. Usted tiene el derecho de cancelar su cuenta de usuario si SPRINGLABS incumple sus obligaciones en virtud de estos Términos y en tal caso, tendrá derecho a un reembolso prorrateado de cualquier cargo prepago. La cancelación de la cuenta de usuario incluirá la denegación de acceso a todos los servicios, la eliminación de información en su cuenta de Usuario, tal como su dirección de correo electrónico y contraseña, y la eliminación de todos los datos en su cuenta de Usuario.

Cargos y Pagos
Los Servicios están disponibles en virtud de los planes de suscripción de diferentes duraciones, modalidades y/o beneficios. Los pagos de los Servicios se pueden hacer con diferentes medios de pago de elección, según lo disponga la Plataforma Web. Su suscripción se renovará automáticamente al final de cada período de suscripción a menos que cambie su plan de suscripción paga a un plan gratuito o nos informe que no desea renovar la suscripción. En el momento de la renovación automática, el cargo de suscripción se aplicará a la tarjeta de crédito que utilizó por última vez o de cualquier otro medio con el que haya pagado. Le ofrecemos la opción de cambiar los datos si desea que el pago de la renovación se realice a través de una tarjeta de crédito diferente. Si no desea renovar la suscripción, debe informarnos al menos siete días antes de la fecha de renovación. Si no cambió su plan a un uno gratuito y si no nos informó que no desea renovar la suscripción, asumiremos que autorizó a SPRINGLABS a aplicar el cargo de suscripción a la tarjeta de crédito o de cualquier otro medio que utilizó por última vez.
Ocasionalmente, es posible que cambiemos el precio de los Servicios o apliquemos un cargo por el uso de los servicios que actualmente están disponibles de forma gratuita. Cualquier aumento en los cargos se aplicará según lo vaya contratando. No se aplicarán cargos por usar ningún Servicio a menos que haya optado por un plan de suscripción pago.
Por los Servicios prestados en virtud de la Contratación, el Usuario se obliga a pagar a SPRINGLABS las tarifas que las Partes acuerden mediante el uso de esta Plataforma Web. Cada Parte deberá hacerse responsable del cumplimiento de sus obligaciones tributarias de conformidad a la ley.
Para nuevos Servicios, Servicios adicionales o modificaciones de los Servicios actuales, las tarifas serán las acordadas en la respectiva sección de los Servicios, contratados por el Usuario. En caso de no pagar dentro de los días señalados en el párrafo anterior, el Usuario tendrá que pagar un interés del 6% mensual sobre el saldo insoluto.
En caso de que el Usuario incumpla con la fecha de pago de los Servicios contratado, es decir que no pague dentro del plazo señalado en la Contratación, además del pago del interés mencionado, SPRINGLABS podrá a su libre discreción bloquear o restringir de manera total o parcial el acceso a el Software, Plataformas Web y Servicios contratados, esto hasta que el Usuario cumpla con su obligación de pago por los Servicios.

Uso de Datos y Plataformas
Las Partes convienen en que el presente contrato se sujetará a las obligaciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares. Asumiendo que los datos personales que sean proporcionados a cada una de las Partes serán protegidos de forma administrativa, técnica y física para evitar pérdidas, usos incorrectos o accesos no autorizados, publicación, modificación o destrucción de los datos personales. En caso contrario se sujetarán a lo previsto por la ley mencionada.
El Usuario reconoce y acepta que SPRINGLABS puede almacenar, usar, reproducir, modificar y transferir a sus filiales, proveedores y subcontratistas, los datos del Usuario, incluidos los datos personales, únicamente en relación con la prestación de los Servicios en virtud de estos Términos.
El Usuario además reconoce y acepta que SPRINGLABS puede almacenar, usar, reproducir, modificar y transferir datos que no están relacionados con una persona física identificada o identificable, incluidos los datos agregados o no identificados, sin limitación, para sus fines comerciales internos, incluyendo pero no se limita a fines tales como análisis, garantía de calidad, mejora de productos y servicios y desarrollo de nuevos productos y servicios. El Usuario acepta hacer que cualquier usuario administrativo, usuario o cliente del Usuario y participante o parte involucrada de los Servicios acepte los mismos términos que en esta sección.

El Usuario declara y garantiza a SPRINGLABS que los datos del Usuario y sus clientes solo contendrán Datos Personales respecto de los cuales el usuario haya proporcionado todos los avisos y divulgaciones (incluso a cada sujeto de los Datos), obtenido todos los consentimientos y permisos de terceros aplicables y de lo contrario tiene toda la autoridad, en cada caso según lo requerido por las leyes aplicables, para permitir que SPRINGLABS brinde los Servicios, incluso con respecto a la recopilación, almacenamiento, acceso, uso, divulgación y transmisión de Datos Personales, incluidos SPRINGLABS y o de todos los terceros aplicables (incluidos los proveedores de cualquier plataforma de mensajería de terceros); y al conectar la solución empresarial de WhatsApp a través de la plataforma SPRINGLABS o sus proveedores, el cliente cumplirá con los términos de la solución empresarial de WhatsApp (https://www.whatsapp.com/legal/business-solution-terms/) en todo momento al acceder y utilizar la solución empresarial de WhatsApp a través de la plataforma SPRINGLABS o sus proveedores.

Cualquier plataforma de terceros, aplicaciones, API, entre otras, que el Usuario utilice o integre de manera directa o por medio de SPRINGLABS o sus proveedores, será responsabilidad única del Usuario; sobre todo la funcionalidad y interoperabilidad entre la Plataforma Web de SPRINGLABS y cualquiera de terceros. SPRINGLABS no es responsable de ninguna plataforma o respecto de cualquier interferencia con el uso de los Servicios o de la cuenta de Usuario, mucho menos con incumplimientos o afectaciones de seguridad o privacidad derivados o atribuibles a cualquier externo.

El Usuario declara conocer y acepta que le serán aplicables los términos de uso y políticas de privacidad de Facebook, según sean variados por Facebook de tiempo en tiempo, según los cuales Facebook dará cierto tratamiento a su información. 
Las actividades previstas en este Contrato no incluyen el tratamiento de datos personales que revelan el origen racial o étnico, opiniones políticas, creencias religiosas o filosóficas, asociación a sindicatos, tratamiento de datos genéticos, datos biométricos con el objetivo de identificar de manera exclusiva a una persona física, datos relativos a la salud o la vida sexual o a la orientación sexual de una persona física ("Datos sensibles"). 

Licencia para uso del Software y SPRINGLABS API
Sujeto al cumplimiento del Usuario con estos Términos, SPRINGLABS le otorga al Usuario un derecho y licencia limitada, no exclusiva, no transferible, no sub-licenciable, revocable durante el plazo de Contratación de los Servicios para: 1) Descargar, instalar y use el Software de SPRINGLABS desde las Plataformas Web de SPRINGLABS y otros sitios vinculados desde el sitio web y demás Plataformas Web de SPRINGLABS únicamente para desarrollar la funcionalidad dentro de las aplicaciones del Usuario para facilitar las comunicaciones entre una empresa y los participantes del chat a través de la plataforma SPRINGLABS de acuerdo con los términos de estos Términos y cualquier otra política o directriz publicada por SPRINGLABS de vez en cuando; e 2) incrustar dentro de la cuenta y plataforma del Usuario los componentes redistribuibles del software SPRINGLABS en forma de código de objeto.
Sujeto al cumplimiento del Usuario con los Términos, SPRINGLABS le otorga al Usuario un derecho y licencia limitada, no exclusiva, no transferible, no sublicenciable, revocable durante el plazo de Contratación de los Servicios para usar la API de SPRINGLABS únicamente (i) para habilitar la aplicación o plataforma web del Usuario para interoperar con la Plataforma Web de SPRINGLABS o (ii) para desarrollar o habilitar Aplicaciones comerciales integradas personalizadas que solo el Usuario utilizará para interactuar con la Plataforma Web y los Servicios de SPRINGLABS de acuerdo con los Términos y cualquier otra política y pautas publicadas por SPRINGLABS.

IV.- RESTRICCIONES Y RESPONSABILIDAD
SPRINGLABS se reserva el derecho de ceder en cualquier momento y sin previa autorización, los derechos y obligaciones, de manera parcial o total, relacionados con el presente acuerdo de voluntades, es decir de los presentes Términos, a cualquier persona, sea física o jurídica, que se encuentre relacionada con SPRINGLABS, pudiendo ser partes relacionadas, filiales, subsidiarias, empresas controladoras, entre otros.
Además de los demás Términos, usted no deberá: (i) transferir los Servicios o de otro modo ponerlos a disposición de un tercero; (ii) prestar un servicio basado en los Servicios sin un permiso previo por escrito; (iii) utilizar los enlaces a sitios de terceros sin aceptar los términos y condiciones del sitio web; (iv) publicar enlaces a sitios de terceros o utilizar su logotipo, nombre de la empresa, etc. sin su permiso previo por escrito; (v) publicar cualquier información personal o confidencial perteneciente a cualquier persona o entidad sin obtener el consentimiento de esa persona o entidad; (vi) usar los Servicios de cualquier forma que pudiera dañar, deshabilitar, sobrecargar, deteriorar o dañar cualquier servidor, red, sistema informático o recurso de SPRINGLABS; (vii) infringir cualquier ley local, estatal, nacional o internacional; y (viii) crear una identidad falsa para engañar a cualquier persona en lo que respecta a la identidad o el origen de cualquier comunicación.
Serán de exclusiva responsabilidad del Usuario todos los Contenidos, diseños, imágenes, etcétera, que en definitiva se publiquen, publiciten, exhiban, ofrezcan, promocionen, etcétera, en sus canales de comunicación de Internet, en virtud de la Contratación de servicios.
Usted acepta ser el único responsable por su Contenido y el contenido de sus transmisiones a través de los Servicios. Usted acepta no utilizar los servicios con fines ilegales o para la transmisión de material que sea ilegal, calumnioso, acosador, difamatorio, invasor de la privacidad, abusivo, amenazante, dañino, vulgar, obsceno, pornográfico o que sea cuestionable, ofenda los sentimientos religiosos, promueva el racismo, contenga virus o código malicioso, o que infrinja o pueda infringir los derechos de propiedad intelectual u otros derechos de otra persona. Usted acepta no utilizar los servicios para la transmisión de “correo basura”, “correo no deseado”, “cartas en cadena”, “phishing” o la distribución masiva de correo no solicitado. Nos reservamos el derecho de suspender su acceso a los servicios si hay motivos razonables para creer que los servicios se utilizaron para cualquier actividad ilegal o no autorizada.
El Usuario acepta los Servicios y Plataforma Web de SPRINGLABS en sus condiciones y tal y como está. SPRINGLABS no garantiza que los Servicios serán ininterrumpidos, oportunos, adecuados, seguros, ni libres de errores. El uso de cualquier material o contenido a través de los Servicios será su responsabilidad y riesgo.
SPRINGLABS no tendrá responsabilidad alguna por los daños y perjuicios de cualquier naturaleza, sean directos o indirectos, previstos o imprevistos, que puedan deberse a la creación, transmisión, publicación o difusión de Contenidos que el Usuario efectúe a través de los Servicios, del Software o de la Plataforma Web.
Asimismo, Usted acepta que SPRINGLABS en ningún caso será responsable de pérdidas, incidentes, daños y/o perjuicios ocasionados al Usuario por: La presencia de virus u otros elementos que puedan producir daño, alteraciones o inconvenientes en los equipos y programas del Usuario, o en los archivos o documentos contenidos de éstos; Cualquier menoscabo, daño u otro deterioro que sufra la información que se suministre a través de los softwares por la acción de hackers, piratas informáticos o cualquier interrupción, suspensión o imperfecto de la propia red Internet; Caídas del sistema ocasionadas por fuerza mayor, caso fortuito, hechos de la naturaleza, actos terroristas, incendio, delitos y por cualquier otra causa no imputable al prestador o al propietario de los softwares; Problemas de conexión en internet y/o red telefónica; Mal uso del sistema por parte del Usuario; Errores en los datos ingresados por el Usuario; Fallas en el computador del Usuario; Pérdida de información del negocio o de otra índole que deriven del uso de la incapacidad de uso del Servicio o a causa de estos, incluso si se advirtió a SPRINGLABS de la posibilidad de dicha afectación; Bajo nivel de respuesta de los Usuarios o usuarios de los softwares en períodos donde los clientes generan una excesiva demanda por el uso de los sistemas; Fallas o deficiencias en canales de comunicación de terceros como Twitter, Facebook, Instagram, Whatsapp u otros. En ningún caso la responsabilidad de SPRINGLABS podría exceded de los cargos que el Usuario haya pagado por el servicio.
SPRINGLABS no asume responsabilidad civil alguna frente a terceros por cualquier acto que, a través de los Servicios proporcionados, en que incurra el Usuario y que afecte los intereses, derechos o patrimonio de terceros, ya sean entidades públicas o privadas, así mismo, no se asume responsabilidad alguna por la publicación de contenido que pudiera implicar la comisión de delitos en cualquier demarcación territorial donde se difunda dicho contenido. Consecuentemente, el Usuario acepta sacar en paz y a salvo a SPRINGLABS de cualquier reclamación judicial o extrajudicial que le sea interpuesta por actos relacionados con el comportamiento del Usuario en la utilización de los Servicios que en el presente documento se refirieren.
En todo caso Usted acepta indemnizar a SPRINGLABS, sus funcionarios, directores, empleados, proveedores y afiliado, de cualquier pérdida, daño, multo y gastos, incluidos honorarios y costos de juicio y abogados) que surjan de reclamaciones judiciales o extrajudiciales respecto de la infracción de los derechos de un terceros por su uso de los Servicios o en relación con los mismo, o de reclamos por infracciones de leyes o normas.
Las Partes declaran que garantizan que adoptan las medidas necesarias de prevención de delitos que resulten suficientes para dar cumplimiento a los diverso marcos normativos aplicables, incluyendo responsabilidad relacionadas a delitos como lavado de activos, financiamiento al terrorismo, cohecho a funcionarios públicos, entre otros.

V.- PROPIEDAD INTELECTUAL
Todos los derechos de propiedad intelectual e industrial sobre los signos distintivos y el Software, incluidas las marcas registrada como SPRINGLABS, Gestión Multiplataforma y Chatbot Multiplataforma, sus actualizaciones o mejoras, código fuente, lenguaje y programación, documentación técnica, manuales de uso, son de titularidad de SPRINGLABS y asegura que no utiliza propiedad intelectual o propiedad industrial de terceros, sin contar con las autorizaciones legales correspondientes, y que la propiedad intelectual y propiedad industrial utilizada, ya sea propia o de terceros, no infringe derechos de propiedad, de propiedad intelectual, de propiedad industrial o de cualquier otra naturaleza.
Las Partes acuerdan que los derechos y la titularidad del Software dispuesto o licenciado son propiedad de SPRINGLABS, por lo que el Usuario sólo podrán utilizarlo mientras cuente con una licencia o contrato vigente. Así mismo, el Usuario reconoce que la interface es propiedad de SPRINGLABS, comprometiéndose a usarlo únicamente para los fines descritos en el presente instrumento.
Adicionalmente, Las Partes se obligan a no hacer mal uso de la imagen, logotipos, tipografía, marcas, diseños o imágenes en la publicidad de la otra parte so pena de las leyes de propiedad industrial.

El uso de cualquier encabezado de página, logo, nombre comercial, gráfico, marca, texto, botones, audio, códigos y software incorporado al Servicio, se encuentra protegido por la legislación mexicana, según la Ley sobre Derechos de Autor. Por lo tanto, queda prohibido su uso, reproducción, modificación, impresión y publicación, caso contrario será sancionado por la legislación aplicable. 

VI.- PRIVACIDAD Y CONFIDENCIALIDAD
La información personal que proporcione a SPRINGLABS a través del Servicio y las Plataformas Web se rige por la Política de Privacidad de SPRINGLABS. El uso el Servicio indica su aceptación de los términos de la Política de Privacidad de SPRINGLABS.
Usted es responsable de mantener la confidencialidad de su nombre de Usuario, contraseña y otra información confidencial. Usted es responsable de todas las actividades que ocurran en su cuenta de usuario y se compromete a informarnos inmediatamente de cualquier uso no autorizado de su cuenta de usuario a nuestros correos electrónicos de contacto señalados en nuestro sitio web.
No somos responsables por las pérdidas o los daños que usted o cualquier tercero sufra a causa del acceso o el uso no autorizado de su cuenta de Usuario, o por cualquier otro motivo
Las Partes se obligan a no divulgar información confidencial con respecto a los documentos e informaciones que se intercambien, tengan acceso o cualquier tipo de información que las Partes expresamente determinen como confidencial y que guarde relación con la Contratación.
Para los efectos de la Contratación será considerada información confidencial toda aquella información revelada por una parte contratante (en adelante la parte divulgante) sea directamente o a través de un representante o su personal, a la otra parte (en adelante la parte receptora) señalada como confidencial o de su propiedad y que no sea de dominio público ya sea en forma verbal o escrita.
Asimismo, se entenderá incluido dentro del término información confidencial todo tipo de claves de acceso a computadores y diseños de programas; diseños de Software de computadores propios y configuraciones de hardware; tecnología propia; operaciones en Internet; estudios estratégicos de la compañía; antecedentes de recursos humanos: estructura de la organización y toda información que sea revelada a raíz de la ejecución del presente contrato por una parte a la otra.
Las Partes, en particular la parte receptora, se obligan a adoptar las medidas necesarias y procedentes a efectos de exigir la máxima discreción y secreto profesional con respecto a cualquier información de la parte divulgante sobre la que lleguen a tener acceso con motivo de la Contratación. Asimismo, se comprometen a no publicar ni divulgar ningún dato que se considere confidencial.
Los datos, información y resultados comerciales que sean revelados por una parte a la otra o a los que se tengan acceso, serán propiedad de la parte divulgante y constituyen un secreto industrial de este en términos de las leyes de confidencialidad, propiedad industrial o cualquier otra ley civil o penal aplicable y por lo tanto quedan sujetos a dichos ordenamiento legales, por lo que la parte receptora no podrá divulgarlos sin la autorización expresa y por escrito de la parte divulgante o propietaria aceptando desde este momento que la violación o incumplimiento de lo dispuesto en la presente cláusula causará daños y perjuicios o compensación en beneficio de la parte divulgante o afectada.
Asimismo, las Partes serán responsables respecto de cualquier mal uso o de la revelación a terceros no autorizados expresamente por escrito, que de la información confidencial o propietaria, hicieran directamente o por medio de su personal; por lo que, las Partes deberán hacer del conocimiento de todos sus empleados y dependientes que tengan acceso a la información, de sus obligaciones respecto a la misma, siendo que deberán de usar y ser requeridos de observar con carácter de absoluta confidencialidad y reserva, la documentación e información en cuestión.
La obligación de confidencialidad de qué trata este apartado sobrevivirá a la Contratación incluso una vez terminado por cualquier causa, por lo menos durante 3 años posteriores a su terminación.

VII.- COMUNICACIONES
Para el adecuado desarrollo y ejecución de los Términos, tanto el Usuario como SPRINGLABS deberán designar a uno o varios representantes autorizados para coordinar, cualquier materia o aspecto que diga relación con el debido cumplimiento a la Contratación. En el caso del Usuario cualquier comunicación o acción de coordinación por medio de los Servicios o Plataforma Web que emane de alguna persona distinta a dichos representantes tendrá pleno valor, pues los acceso a la plataforma son responsabilidad del Usuario.
Las Partes acuerdan que la notificación o comunicación oficial para fines de los Servicios, ampliaciones, modificaciones, nuevos servicios, entre otros, y sus tarifas respectivas podrán acordarse, con validez a la Contratación, por los correos electrónicos señalados, a solicitud del Usuario y bajo aprobación de SPRINGLABS, sin necesidad de la firma de anexos o documentos adicionales.
El Servicio puede incluir ciertas comunicaciones de SPRINGLABS, tales como anuncios de servicio, mensajes administrativos y boletines informativos. Usted entiende que estas comunicaciones se considerarán parte del uso de los servicios. Como parte de nuestra política para brindarle privacidad total, también le proporcionamos la opción de optar por no recibir información nuestra. Sin embargo, no podrá optar por dejar de recibir anuncios de servicio y mensajes administrativos.
Las Partes podrán modificar los nombres de aquellas personas que deban recibir notificaciones en su representación y sus respectivas direcciones, números de teléfono y correos electrónicos, perfiles de usuarios, administradores, entre otros, siempre que tal notificación sea debidamente notificada o ajustada por medio de la Plataforma Web en los campos correspondientes.
Si recibimos una queja de alguien en su contra con respecto a sus actividades como parte del uso de los Servicios, remitiremos la denuncia a la dirección de correo electrónico principal de su cuenta de Usuario. Debe responder al denunciante directamente dentro de un plazo de 10 días a partir de la recepción de la denuncia que le reenviamos y copiar SPRINGLABS en la comunicación. Si no responde al denunciante dentro del plazo de 10 días a partir de la fecha en que recibió nuestros mensajes de correo electrónico, es posible que divulguemos su nombre e información de contacto al denunciante para que este inicie acciones legales en su contra. Usted entiende que la falta de respuesta a la denuncia remitida dentro del plazo de 10 días se interpretará como su consentimiento para que SPRINGLABS divulgue su nombre e información de contacto al denunciante.

VII.- LEY APLICABLE Y JURISDICCIÓN
Para la interpretación y cumplimiento de la presente contratación, las Partes se someten a la jurisdicción y la competencia de las Leyes y los tribunales ordinarios de justicia de la Ciudad de Monterrey, Nuevo León, México, renunciando expresamente al fuero que por razón de sus domicilios presentes o futuros pudiera corresponderles, con excepción de las acciones de cobranza, que podrán efectuarse en el país de domicilio del Usuario. La Convención de Naciones Unidas sobre los Contratos de Compraventa Internacional de mercaderías no aplicará a los presente Términos y acuerdos.

Los presentes Términos son de aplicación únicamente en la medida en que así lo autorice la ley. Cualquier utilización de los Servicios requiere la aceptación sin reserva de los presentes Términos de SPRINGLABS.

Le recomendamos añadir la página web de SPRINGLABS a sus favoritos y consultar periódicamente este sitio para enterarse de cualquier posible actualización de los Términos.
SPRINGLABS recibirá con gusto sus opiniones, preguntas, inquietudes, comentarios o sugerencias a través de los medios oficiales de comunicación.

Mediante la aceptación de los presentes Términos otorga su consentimiento para que la responsable, es decir SPRINGLABS, actúe conforme a los Términos aquí planteados por medio de cualquiera de sus representantes legales.
`}
        </p>
      </Grid>
    </Grid>
  )
}

export default SectionOne
